import Advantages, { AdvantagesItem, ALIGNS } from "../Advantages"
import React from "react"
import classNames from "classnames"
import styles from "./styles.module.scss"

export interface BaseActivityProps {
	className?: string
	items: AdvantagesItem[]
	title: string
	supTitle: string
}

export default function BaseActivity(props: BaseActivityProps) {
	return (
		<div className={classNames(props.className, styles.baseActivity)}>
			<div className={styles.caption}>
				<div
					className={styles.supTitle}
					dangerouslySetInnerHTML={{ __html: props.supTitle }}
				/>
				<div
					className={styles.title}
					dangerouslySetInnerHTML={{ __html: props.title }}
				/>
			</div>
			<Advantages
				className={styles.advantages}
				hideImages={false}
				align={ALIGNS.CENTER}
				items={props.items}
				breakpoints={{
					0: {
						slidesPerView: 1,
						pagination: {
							clickable: true,
						},
					},
					500: {
						slidesPerView: 2,
					},
					900: {
						slidesPerView: 3,
					},
					1320: {
						slidesPerView: 4,
						pagination: false,
					},
				}}
			/>
		</div>
	)
}
