import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { pagesLinks } from "../../../pages-data/_V2/common/links"
import styles from "./styles.module.scss"

export default {
	pageName: <>Интеграция с&nbsp;Delivery Club</>,
	desktopTitle: <>Прирост заказов на&nbsp;20%</>,
	subTitle: (
		<>
			Интеграция расширяет зону доставки, открывает доступ к&nbsp;аудитории
			агрегатора. Гибкая работа со&nbsp;службой доставки&nbsp;&mdash; сам
			выбираешь чьи курьеры отвезут заказ.
		</>
	),
	cta: pagesLinks.registration.text,
	desktopCtaDetails:
		"За 10 минут, без дорогого оборудования,\nс удобным интерфейсом. И две \nнедели бесплатно.",
	ctaLink: pagesLinks.registration,
	figure: (
		<StaticImage
			src="./assets/delivery-club-presentation.png"
			alt="интеграция с delivery club"
			className={styles.image}
			objectFit="contain"
			placeholder="blurred"
			quality={90}
		/>
	),
}
