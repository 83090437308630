import React from "react"
import SolidTabs, { SOLID_TABS_THEMES } from "../../../components/_V2/SolidTabs"
import data from "./data"

interface Props {
	className?: string
}

export default function OrderFeatures(props: Props) {
	return (
		<SolidTabs
			className={props.className}
			items={data.items}
			theme={SOLID_TABS_THEMES.DEFAULT}
			transformOnAdaptive={true}
		/>
	)
}
