import React from "react"
import classNames from "classnames"
import DefaultLayout from "../layout/default"

import pageStyles from "../styles/_V2/pagesStyles/styles.module.scss"
import styles from "./styles/_V2/deliveryClub.module.scss"
import Advantages from "../components/_V2/Advantages"
import BusinessTypes from "../components/_V2/BusinessTypes"

import DeliveryClubProductPresentation from "../pagesSections/delivery-club/ProductPresentation"
import AdditionalServices from "../pagesSections/index/AdditionalServices"
import CtaForm from "../pagesSections/index/CtaForm"
import KnowledgeBase from "../pagesSections/index/KnowledgeBase"

import OrderFeatures from "../pagesSections/delivery-club/OrderFeatures"
import AccountingFeatures from "../pagesSections/delivery-club/AccountingFeatures"
import StatisticsActivity from "../pagesSections/delivery-club/StatisticsActivity"

import Presentation from "../pagesSections/emenu/ProductPresentation/assets/emenu-presentation.png"

import deliveryClubAdvantages from "../pages-data/_V2/delivery-club/advantages"

import { businessTypes } from "../pages-data/_V2/common/business-types-compact"
import { useLocalizationContext } from "../localization/useLocalizationContext";

export default function DeliveryClub() {
	const localizationContext = useLocalizationContext();

	const metaTags = [
		{
			name: "description",
			content:
				"Готовый канал продаж ✔ Интеграция расширяет зону доставки, открывает доступ к аудитории агрегатора ✔ Все заказы в одной системе ✔ Выбирай, кто будет доставлять заказы — курьеры сервиса-агрегатора или свои сотрудники → Меню и акции обновляются автоматически в приложении и на сайте агрегатора.",
		},
		// {
		//   name: 'keywords',
		//   content: 'программа, лояльность, гости, мобильное приложение, предзаказ, пуш-уведомление, автоматизация, кафе, ресторан',
		// },
		{
			property: "og:url",
			content: "https://quickresto.ru/delivery-club/",
		},
		{
			property: "og:title",
			content: "Интеграция Quick Resto и Delivery Club",
		},
		{
			property: "og:description",
			content:
				"Готовый канал продаж ✔ Интеграция расширяет зону доставки, открывает доступ к аудитории агрегатора ✔ Все заказы в одной системе ✔ Выбирай, кто будет доставлять заказы — курьеры сервиса-агрегатора или свои сотрудники → Меню и акции обновляются автоматически в приложении и на сайте агрегатора.",
		},
		{
			property: "og:type",
			content: "website",
		},
		{
			property: "og:image",
			content: `https://quickresto.ru${Presentation}`,
		},
	]

	return (
		<DefaultLayout
			title="Интеграция Quick Resto и Delivery Club"
			metaTags={metaTags}
			mainWrapperClassName={pageStyles.pageWrapper}
			linkCanonical="https://quickresto.ru/delivery-club/"
			footerClassName={pageStyles.pageSection}
		>
			<DeliveryClubProductPresentation />

			<Advantages
				items={deliveryClubAdvantages}
				className={classNames(
					styles.deliveryClub__advantages,
					pageStyles.pageSection
				)}
			/>

			<StatisticsActivity
				className={classNames(
					pageStyles.pageSection,
					styles.deliveryClub__activity
				)}
			/>

			<OrderFeatures className={pageStyles.pageSection} />

			<AccountingFeatures className={pageStyles.pageSection} />

			<AdditionalServices className={pageStyles.pageSection} />

			<CtaForm isFry={false} className={pageStyles.pageSection} />

			<BusinessTypes key={localizationContext.locale} data={businessTypes(localizationContext.locale)} className={pageStyles.pageSection} />

			<KnowledgeBase type="horizontal" className={pageStyles.pageSection} />
		</DefaultLayout>
	)
}
