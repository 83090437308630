import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { BaseActivityProps } from "../../../components/_V2/BaseActivity"

const data: BaseActivityProps = {
	supTitle: "понятно как зарабатывать",
	title: "Самое большое количество активных клиентов",
	items: [
		{
			img: (
				<StaticImage
					src="./assets/advantage-1.svg"
					alt=""
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			title: "200 000 +",
			description: "Новых клиентов ежемесячно",
		},
		{
			img: (
				<StaticImage
					src="./assets/advantage-2.svg"
					alt=""
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			title: "12 000 000",
			description: "Скачиваний \nприложения",
		},
		{
			img: (
				<StaticImage
					src="./assets/advantage-3.svg"
					alt=""
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			title: "100 000 +",
			description: "Заказов ежедневно",
		},
		{
			img: (
				<StaticImage
					src="./assets/advantage-4.svg"
					alt=""
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			title: "4 000 000 +",
			description: "Ежемесячная \nаудитория",
		},
	],
}

export default data
