import React from "react"
import { SolidTabsProps } from "../../../components/_V2/SolidTabs"
import styles from "./styles.module.scss"
import { StaticImage } from "gatsby-plugin-image"
import classNames from "classnames"

const data: SolidTabsProps = {
	items: [
		{
			tabName: "Заказы",
			desktopTitle: "Все заказы в одной системе",
			mobileTitle: "Все заказы в одной системе",
			tabletTitle: "Все заказы в одной системе",
			desktopContent: (
				<p>
					Принимай все заказы на&nbsp;кассовом терминале Quick Resto.
					<br />
					Не&nbsp;нужно использовать второй планшет для заказов
					от&nbsp;агрегатора.
				</p>
			),
			tabletContent: (
				<p>
					Принимай все заказы на&nbsp;кассовом терминале Quick Resto.
					Не&nbsp;нужно использовать второй планшет для заказов
					от&nbsp;агрегатора.
				</p>
			),
			mobileContent: (
				<p>
					Принимай все заказы на&nbsp;кассовом терминале Quick Resto.
					Не&nbsp;нужно использовать второй планшет для заказов
					от&nbsp;агрегатора.
				</p>
			),
			mediaContent: (
				<div className={styles.images__container}>
					<StaticImage
						className={styles.image}
						src="./assets/delivery-club-order-1.png"
						alt={"заказы на доставку из ресторана"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				</div>
			),
			backdropContent: <div className={styles.backdrop} />,
		},
		{
			tabName: "Способы доставки",
			desktopTitle: "Удобно управлять",
			mobileTitle: "Удобно управлять",
			tabletTitle: "Удобно управлять",
			desktopContent: (
				<>
					Выбирай, кто будет доставлять заказы&nbsp;&mdash; курьеры
					сервиса-агрегатора или свои сотрудники.
					<br />
					Управление прямо с&nbsp;кассового терминала. Незаменимо для пиковых
					часов.
				</>
			),
			tabletContent: (
				<>
					Выбирай, кто будет доставлять заказы&nbsp;&mdash; курьеры
					сервиса-агрегатора или свои сотрудники. Управление прямо
					с&nbsp;кассового терминала. Незаменимо для пиковых часов.
				</>
			),
			mobileContent: (
				<>
					Выбирай, кто будет доставлять заказы&nbsp;&mdash; курьеры
					сервиса-агрегатора или свои сотрудники. Управление прямо
					с&nbsp;кассового терминала. Незаменимо для пиковых часов.
				</>
			),
			mediaContent: (
				<div className={styles.images__container}>
					<StaticImage
						className={classNames(styles.image)}
						src={"./assets/delivery-club-order-2.png"}
						alt={"доставка курьерской службой"}
						objectFit="contain"
						breakpoints={[223, 642, 690]}
						quality={90}
					/>
				</div>
			),
			backdropContent: <div className={styles.backdrop} />,
		},
		{
			tabName: "Авторизация гостя",
			desktopTitle: "Гостю приятно, когда его узнают",
			mobileTitle: "Гостю приятно, когда его узнают",
			tabletTitle: "Гостю приятно, когда его узнают",
			desktopContent: (
				<>
					Введи имя или телефон&nbsp;&mdash; карточка с&nbsp;адресом доставки
					отобразится на&nbsp;экране.
					<br />
					Если гость ещё ничего не&nbsp;заказывал, карточку можно быстро
					создать.
				</>
			),
			tabletContent: (
				<>
					Введи имя или телефон&nbsp;&mdash; карточка с&nbsp;адресом доставки
					отобразится на&nbsp;экране. Если гость ещё ничего не&nbsp;заказывал,
					карточку можно быстро создать.
				</>
			),
			mobileContent: (
				<>
					Введи имя или телефон&nbsp;&mdash; карточка с&nbsp;адресом доставки
					отобразится на&nbsp;экране. Если гость ещё ничего не&nbsp;заказывал,
					карточку можно быстро создать.
				</>
			),
			mediaContent: (
				<div className={styles.images__container}>
					<StaticImage
						className={classNames(styles.image)}
						src={"./assets/delivery-club-order-3.png"}
						alt={"авторизация гостя на терминале"}
						objectFit="contain"
						breakpoints={[223, 642, 690]}
						quality={90}
					/>
				</div>
			),
			backdropContent: <div className={styles.backdrop} />,
		},
	],
}

export default data
