export default [
	{
		title: "Готовый канал продаж",
		description:
			"Размести меню и&nbsp;акции в&nbsp;агрегаторе бесплатно. Комиссия начисляется только с&nbsp;заказов.",
	},
	{
		title: "Рост прибыли",
		description:
			"За&nbsp;счёт увеличения числа заказов в&nbsp;первый месяц после размещения в&nbsp;Delivery Club.",
	},
	{
		title: "37 минут",
		description:
			"Среднее время доставки заказов в&nbsp;сервисе Delivery Club. Клиенты будут довольны.",
	},
]
